import { useFlagsStatus } from '@unleash/proxy-client-react';
import React, { Suspense, useEffect } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  Blockchain,
  FeatureFlags,
  Prize as PrizeCommon,
  Storage as StorageCommon,
  Wallet as WalletCommon,
} from 'ultimate-league-common';
import { fromWei } from 'web3-utils';

import { useBootstrap } from '#common/bootstrap';
import { GameWeeksStore } from '#common/game-week';
import { useFooterConfig, useHeaderConfig } from '#common/navigation';
import {
  buildRoutePath,
  getRoutePath,
  replaceSportParam,
} from '#common/routing';
import { useCurrentSport } from '#common/sport';
import {
  TotalParticipantsContainer,
  usePrizes,
  useUserAccount,
} from '#common/store';
import { useFlag } from '#technical/feature-flags';
import { getPublicURL } from '#technical/storage';
import { t } from '#technical/translate';
import {
  Alert,
  alertClasses,
  Button,
  Link,
  PageContent,
  Spacer,
} from '#ui/components';

import { Account } from './account';
import { Athlete } from './athlete';
import { Auction } from './auction';
import { Auctions } from './auctions';
import { BuyChampRoute } from './buy-champ/BuyChampRoute';
import { Card } from './card';
import { Collection } from './collection';
import { Contact } from './contact';
import { FTUEGuard } from './ftue';
import { Leagues } from './leagues';
import { Marketplace } from './marketplace';
import { Footer, Header, IHeaderProps } from './navigation';
import { PacksRouter } from './packs';
import { Rewards } from './rewards';
import { GameRoute } from './routing';
import { Tournaments } from './tournaments';
import { Wallet } from './wallet';

const EndingBanner = styled(Alert)`
  &.${alertClasses.root} {
    background-color: ${({ theme }) => theme.color.warning['80']};

    .${alertClasses.icon} {
      padding: ${({ theme }) => theme.spacing(12)};
      border-radius: 999px;
      color: ${({ theme }) => theme.color.warning['50']};
      background-color: ${({ theme }) => theme.color.warning['70']};
    }
  }

  p {
    color: ${({ theme }) => theme.color.warning['50']}!important;
  }
`;
const ActionButton = styled(Button)`
  border-color: ${({ theme }) => theme.color.warning['70']};

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }
`;

const PlayRouter = () => (
  <FTUEGuard enforceDraft>
    <GameWeeksStore>
      <TotalParticipantsContainer>
        <Switch>
          <Route
            path={[
              getRoutePath('LEAGUES'),
              getRoutePath('LEAGUE_INVITATION'),
              getRoutePath('LEAGUE_INVITATION_LEGACY'),
            ]}
            component={Leagues}
          />

          <Route
            path={[getRoutePath('TOURNAMENTS'), getRoutePath('TOURNAMENT')]}
            component={Tournaments}
          />
        </Switch>
      </TotalParticipantsContainer>
    </GameWeeksStore>
  </FTUEGuard>
);

/* Styled Components */

const RoutePlaceholder = styled.div`
  height: 100vh;
`;

/* Main Component */

export function Game() {
  const { account, balances } = useUserAccount();
  const { pathname } = useLocation();
  const { replace } = useHistory();
  const tournamentsEnabled = useFlag(FeatureFlags.Features.TOURNAMENTS);
  const unaIOEnabled = useFlag(FeatureFlags.Features.UNA_IO);
  const auctionsEnabled = useFlag(FeatureFlags.Features.AUCTIONS);
  const { flagsReady } = useFlagsStatus();

  const { sport: currentSport, switchSport } = useCurrentSport();

  const handleChangeSport: IHeaderProps['onSportChange'] = (sport) => {
    const nextPath = replaceSportParam(pathname, sport);
    if (nextPath) replace(nextPath);
    switchSport(sport);
  };

  useBootstrap();

  useEffect(() => {
    const wonderChatWrapper = document.getElementById('wonderchat-wrapper');

    if (wonderChatWrapper) {
      wonderChatWrapper.style.display = 'block';
    }
  }, []);

  const { items: prizes } = usePrizes();

  const rewards = prizes.filter(
    ({ pack, context }) =>
      !pack.open &&
      pack.sport === currentSport &&
      (context.contextType === PrizeCommon.ContextType.GAMEMODE ||
        context.eventType === PrizeCommon.EventType.REFERRAL_INVITEE ||
        context.eventType === PrizeCommon.EventType.REFERRAL_INVITER)
  );

  const champTokenBalance = balances[Blockchain.Token.Token.UNA];
  const mgcTokenBalance = balances[Blockchain.Token.Token.ULC];

  const headerConfig = useHeaderConfig(currentSport);
  const footerConfig = useFooterConfig();

  const walletIsLoading =
    !account?.wallet ||
    account.wallet.status === WalletCommon.WalletStatus.PENDING;

  const pendingTransactions =
    account?.wallet?.transactions?.filter(
      ({ status }) =>
        status === WalletCommon.TransactionStatus.CREATED ||
        status === WalletCommon.TransactionStatus.SENT
    ) || [];

  if (!flagsReady) return <RoutePlaceholder />;

  return (
    <>
      <Header
        {...headerConfig}
        currentSport={
          headerConfig.sports.find((sport) => sport.type === currentSport)!
        }
        onSportChange={handleChangeSport}
        rewards={rewards.length}
        userData={
          account && {
            userName: account.username ?? '',
            email: account.email,
            image: account.hasProfilePicture
              ? getPublicURL({
                  storageType:
                    StorageCommon.StorageType.PUBLIC_USER_PROFILE_PICTURE,
                  id: account.id,
                  profilePictureVersion: account.profilePictureVersion,
                })
              : undefined,
          }
        }
        wallet={{
          champ: champTokenBalance
            ? Number(fromWei(champTokenBalance, 'ether'))
            : 0,
          mgc: mgcTokenBalance ? Number(fromWei(mgcTokenBalance, 'ether')) : 0,
          url: buildRoutePath('WALLET', {}),
          isLoading:
            !account ||
            !champTokenBalance ||
            !mgcTokenBalance ||
            walletIsLoading ||
            pendingTransactions.length > 0,
        }}
      />
      <Spacer size={32} />
      <PageContent>
        <EndingBanner
          title={t('ENDING_BANNER-TITLE')}
          icon="warning__filled"
          action={
            <ActionButton
              variant="outlined"
              color="warning"
              as="a"
              href="https://beta.ultimate-champions.com/"
              target="_blank"
              size="S"
              leadingAdornment="open_in_new__filled"
              label={t('ENDING_BANNER-ACTION')}
            />
          }
        >
          {t('ENDING_BANNER-MESSAGE', {
            link: (
              <Link
                href="https://beta.ultimate-champions.com/"
                target="_blank"
                textColor={({ warning }) => warning['30']}
              >
                https://beta.ultimate-champions.com/
              </Link>
            ),
          })}
        </EndingBanner>
      </PageContent>
      <Suspense fallback={<RoutePlaceholder />}>
        <Switch>
          <Route
            exact
            path={[
              getRoutePath('LEAGUES'),
              getRoutePath('LEAGUE_INVITATION'),
              getRoutePath('LEAGUE_INVITATION_LEGACY'),
              ...(tournamentsEnabled
                ? [getRoutePath('TOURNAMENTS'), getRoutePath('TOURNAMENT')]
                : []),
            ]}
            component={PlayRouter}
          />
          <Route
            exact
            path={getRoutePath('MARKETPLACE')}
            component={Marketplace}
          />
          {auctionsEnabled && [
            <Route
              key="auction"
              exact
              path={getRoutePath('AUCTION')}
              component={Auction}
            />,
            <Route
              key="auctions"
              exact
              path={getRoutePath('AUCTIONS')}
              component={Auctions}
            />,
          ]}
          <Route
            exact
            path={getRoutePath('COLLECTION')}
            component={Collection}
          />
          <Route
            exact
            path={[getRoutePath('FUNGIBLE_CARD'), getRoutePath('NFT_CARD')]}
            component={Card}
          />
          <Route path={getRoutePath('ATHLETE')} component={Athlete} />
          <Route
            exact
            path={[getRoutePath('PACK_DETAILS'), getRoutePath('PACK_STORE')]}
            component={PacksRouter}
          />
          <Route exact path={getRoutePath('ACCOUNT')} component={Account} />
          <Route exact path={getRoutePath('WALLET')} component={Wallet} />
          <Route exact path={getRoutePath('REWARDS')} component={Rewards} />
          {unaIOEnabled && (
            <Route
              exact
              path={getRoutePath('BUY_CHAMP')}
              component={BuyChampRoute}
            />
          )}
          <Route exact path={getRoutePath('CONTACT')} component={Contact} />

          <Route
            exact
            path={[getRoutePath('GAME'), '*']}
            component={GameRoute}
          />
        </Switch>
      </Suspense>

      <Footer {...footerConfig} />
    </>
  );
}
