"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNftCardItem = exports.isFiatToUNAItem = exports.isULCTokenItem = exports.isUNATokenItem = exports.isPackConfigWithPacksCount = void 0;
var loot_table_1 = require("./loot-table");
var isPackConfigWithPacksCount = function (packConfig) {
    return Object.prototype.hasOwnProperty.call(packConfig, 'availablePacksCount');
};
exports.isPackConfigWithPacksCount = isPackConfigWithPacksCount;
function isUNATokenItem(item) {
    return item.dropType === loot_table_1.DropType.UNA_TOKEN;
}
exports.isUNATokenItem = isUNATokenItem;
function isULCTokenItem(item) {
    return item.dropType === loot_table_1.DropType.ULC_TOKEN;
}
exports.isULCTokenItem = isULCTokenItem;
function isFiatToUNAItem(item) {
    return item.dropType === loot_table_1.DropType.FIAT_TO_UNA;
}
exports.isFiatToUNAItem = isFiatToUNAItem;
function isNftCardItem(item) {
    return item.dropType === loot_table_1.DropType.NFT_CARD;
}
exports.isNftCardItem = isNftCardItem;
