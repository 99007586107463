import { paperClasses } from '@mui/material/Paper';
import MuiSnackbar, {
  SnackbarCloseReason,
  snackbarClasses,
} from '@mui/material/Snackbar';
import {
  CustomContentProps,
  SnackbarContent,
  SnackbarKey,
  VariantType,
  useSnackbar,
} from 'notistack';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Brand } from 'ultimate-league-common';

import { IColorPalettes } from '../../../theme';
import { Button, IconButton } from '../../inputs';
import { Paragraph } from '../../typography';
import { SnackbarAction } from './types';

const getBrandBasedTextColor = (variant: VariantType, colors: IColorPalettes) =>
  variant === 'default'
    ? colors.primary[0]
    : Brand.switchBrand({
        UC: colors.primary['0'],
        LFP: colors.primary['100'],
      });

const getBrandBasedButtonColor = (variant: VariantType) =>
  variant === 'default'
    ? 'default'
    : Brand.switchBrand({ UC: 'default', LFP: 'dark' });

const getBackgroundColor = (color: IColorPalettes) => ({
  default: color.primary['80'],
  error: color.error['50'],
  info: color.info['50'],
  success: color.success['50'],
  warning: color.warning['60'],
});

const StyledSnackbar = styled(MuiSnackbar)<{
  variant: VariantType;
}>`
  display: flex;
  gap: ${({ theme }) => theme.spacing(16)};

  & .${paperClasses.root} {
    max-width: 375px;
    background-color: ${({ theme, variant = 'default' }) =>
      getBackgroundColor(theme.color)[variant]};
  }

  &.${snackbarClasses.root} {
    position: static;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(8)};
`;

export interface ISnackbarProps
  extends Omit<
    CustomContentProps,
    | 'action'
    | 'persist'
    | 'style'
    | 'variant'
    | 'iconVariant'
    | 'hideIconVariant'
  > {
  action?: SnackbarAction;
  closeButton?: boolean;
  severity: VariantType;
}

export const Snackbar = forwardRef<HTMLDivElement, ISnackbarProps>(
  (props, forwardedRef) => {
    const { closeSnackbar } = useSnackbar();

    const handleClose = (key: SnackbarKey, reason?: SnackbarCloseReason) => {
      if (props.closeButton && reason === 'clickaway') {
        return;
      }

      closeSnackbar(key);
    };

    return (
      <SnackbarContent ref={forwardedRef}>
        <StyledSnackbar
          open
          anchorOrigin={props.anchorOrigin}
          variant={props.severity}
          onClose={(_, reason) => handleClose(props.id, reason)}
          action={
            <ActionsContainer>
              {props.action && (
                <Button
                  label={props.action.label}
                  onClick={props.action.onClick}
                  variant="text"
                  size="XS"
                  color={getBrandBasedButtonColor(props.severity)}
                />
              )}
              {props.closeButton && (
                <IconButton
                  icon="close__filled"
                  variant="text"
                  size="XS"
                  onClick={() => handleClose(props.id)}
                  color={getBrandBasedButtonColor(props.severity)}
                />
              )}
            </ActionsContainer>
          }
          message={
            <Paragraph
              variant="M"
              $textColor={(color) =>
                getBrandBasedTextColor(props.severity, color)
              }
              multiline
            >
              {props.message}
            </Paragraph>
          }
        />
      </SnackbarContent>
    );
  }
);
