"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.MinimumListingPrices = void 0;
var brand_1 = require("../../brand");
var NFT_card_1 = require("../NFT-card");
exports.MinimumListingPrices = (0, brand_1.switchBrand)({
    UC: (_a = {},
        _a[NFT_card_1.RarityLevel.FUNGIBLE] = 0,
        _a[NFT_card_1.RarityLevel.RARITY_4] = 0.5,
        _a[NFT_card_1.RarityLevel.RARITY_3] = 0.5,
        _a[NFT_card_1.RarityLevel.RARITY_2] = 1.5,
        _a[NFT_card_1.RarityLevel.RARITY_1] = 200,
        _a),
    LFP: (_b = {},
        _b[NFT_card_1.RarityLevel.FUNGIBLE] = 0,
        _b[NFT_card_1.RarityLevel.RARITY_4] = 0.5,
        _b[NFT_card_1.RarityLevel.RARITY_3] = 2,
        _b[NFT_card_1.RarityLevel.RARITY_2] = 10,
        _b[NFT_card_1.RarityLevel.RARITY_1] = 100,
        _b),
});
