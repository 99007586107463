"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNftCardDrop = exports.isFiatToUNADrop = exports.isULCTokenDrop = exports.isUNATokenDrop = exports.DropType = void 0;
var DropType;
(function (DropType) {
    DropType["NFT_CARD"] = "CARD";
    DropType["UNA_TOKEN"] = "UNA_TOKEN";
    DropType["ULC_TOKEN"] = "ULC_TOKEN";
    DropType["FIAT_TO_UNA"] = "FIAT_TO_UNA";
})(DropType || (exports.DropType = DropType = {}));
function isUNATokenDrop(drop) {
    return drop.dropType === DropType.UNA_TOKEN;
}
exports.isUNATokenDrop = isUNATokenDrop;
function isULCTokenDrop(drop) {
    return drop.dropType === DropType.ULC_TOKEN;
}
exports.isULCTokenDrop = isULCTokenDrop;
function isFiatToUNADrop(drop) {
    return drop.dropType === DropType.FIAT_TO_UNA;
}
exports.isFiatToUNADrop = isFiatToUNADrop;
function isNftCardDrop(drop) {
    return drop.dropType === DropType.NFT_CARD;
}
exports.isNftCardDrop = isNftCardDrop;
