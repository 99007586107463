"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameMode = exports.BracketType = exports.PrizePoolType = exports.DivisionIcon = exports.GameViewIcon = void 0;
var GameViewIcon;
(function (GameViewIcon) {
    GameViewIcon["ALL_STARS"] = "ALL_STARS";
    GameViewIcon["BEST_OF_2022"] = "BEST_OF_2022";
    GameViewIcon["BNB"] = "BNB";
    GameViewIcon["CL1_CAPPED"] = "CL1_CAPPED";
    GameViewIcon["CL1_O28"] = "CL1_OVER_28";
    GameViewIcon["CL1_U23"] = "CL1_UNDER_23";
    GameViewIcon["DIVERSITY_CUP"] = "DIVERSITY_CUP";
    GameViewIcon["ELITE_SERIEN"] = "ELITE_SERIEN";
    GameViewIcon["ENGLAND"] = "ENGLAND";
    GameViewIcon["EURO_CUP"] = "EURO_CUP";
    GameViewIcon["EURO_LEAGUE"] = "EURO_LEAGUE";
    GameViewIcon["EURO24_GER"] = "EURO24_GER";
    GameViewIcon["EURO24_DIAGRAMME"] = "EURO24_DIAGRAMME";
    GameViewIcon["EVENT_GW100"] = "EVENT_GW100";
    GameViewIcon["FC_BARCELONA"] = "FC_BARCELONA";
    GameViewIcon["FIVE_A_SIDE"] = "FIVE_A_SIDE";
    GameViewIcon["FRANCE"] = "FRANCE";
    GameViewIcon["GERMANY_FRANCE_SCOTLAND"] = "GERMANY_FRANCE_SCOTLAND";
    GameViewIcon["GLOBAL"] = "GLOBAL";
    GameViewIcon["HOLIDAY_CUP"] = "HOLIDAY_CUP";
    GameViewIcon["ICELAND"] = "ICELAND";
    GameViewIcon["LIGUE_1"] = "LIGUE_1";
    GameViewIcon["LIGUE_1_BLACK"] = "LIGUE_1_BLACK";
    GameViewIcon["LIGUE_2"] = "LIGUE_2";
    GameViewIcon["LIGUE_2_BLACK"] = "LIGUE_2_BLACK";
    GameViewIcon["MINT"] = "MINT";
    GameViewIcon["MIXED_DIVISION_1"] = "MIXED_DIVISION_1";
    GameViewIcon["MIXED_DIVISION_2"] = "MIXED_DIVISION_2";
    GameViewIcon["OUTSIDER"] = "OUTSIDER";
    GameViewIcon["OVER_28"] = "OVER_28";
    GameViewIcon["OVER_30"] = "OVER_30";
    GameViewIcon["PACKED_FIELD"] = "PACKED_FIELD";
    GameViewIcon["PERSONA"] = "PERSONA";
    GameViewIcon["PLAYSHARPER"] = "PLAYSHARPER";
    GameViewIcon["REDEMPTION"] = "REDEMPTION";
    GameViewIcon["ROMANIA"] = "ROMANIA";
    GameViewIcon["RUMBLE_KONG"] = "RUMBLE_KONG";
    GameViewIcon["SERBIA"] = "SERBIA";
    GameViewIcon["SLOVAKIA"] = "SLOVAKIA";
    GameViewIcon["SPORTADO"] = "SPORTADO";
    GameViewIcon["SUMMER_LEAGUES"] = "SUMMER_LEAGUES";
    GameViewIcon["SUPERLIGA"] = "SUPERLIGA";
    GameViewIcon["TOP_SCORER"] = "TOP_SCORER";
    GameViewIcon["ULTIMATE_CHAMPIONS"] = "ULTIMATE_CHAMPIONS";
    GameViewIcon["UNDERDOG"] = "UNDERDOG";
    GameViewIcon["UNDER_18"] = "UNDER_18";
    GameViewIcon["UNDER_23"] = "UNDER_23";
    GameViewIcon["UNDER_26"] = "UNDER_26";
    GameViewIcon["VEIKKAUSLIGA"] = "VEIKKAUSLIGA";
    GameViewIcon["VIRSLIGA"] = "VIRSLIGA";
})(GameViewIcon || (exports.GameViewIcon = GameViewIcon = {}));
var DivisionIcon;
(function (DivisionIcon) {
    DivisionIcon["BEGINNER"] = "BEGINNER";
    DivisionIcon["EPIC"] = "EPIC";
    DivisionIcon["MYTHIC"] = "MYTHIC";
    DivisionIcon["UNIQUE"] = "UNIQUE";
    DivisionIcon["LEGEND"] = "LEGEND";
})(DivisionIcon || (exports.DivisionIcon = DivisionIcon = {}));
var PrizePoolType;
(function (PrizePoolType) {
    PrizePoolType["SCORE"] = "SCORE";
    PrizePoolType["POSITION"] = "POSITION";
})(PrizePoolType || (exports.PrizePoolType = PrizePoolType = {}));
var BracketType;
(function (BracketType) {
    BracketType["ABSOLUTE"] = "ABSOLUTE";
    BracketType["PERCENTAGE"] = "PERCENTAGE";
})(BracketType || (exports.BracketType = BracketType = {}));
var GameMode;
(function (GameMode) {
    GameMode["LEAGUES"] = "leagues";
    GameMode["TOURNAMENTS"] = "tournaments";
})(GameMode || (exports.GameMode = GameMode = {}));
