"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Role = void 0;
var Role;
(function (Role) {
    Role["USER"] = "user";
    Role["MODERATOR"] = "moderator";
    Role["SUPPORT"] = "support";
    Role["ASSET_RETAKER"] = "asset_retaker";
    Role["COMMUNITY"] = "community";
})(Role || (exports.Role = Role = {}));
