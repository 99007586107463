"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatScore = exports.computePlayerScore = exports.computeConditionModifier = exports.computeModifiers = exports.computeAthleteScore = exports.computeCategory = void 0;
var conditionBonus_1 = require("../../blockchain/NFT-card/conditionBonus");
var gameview_1 = require("../gameview");
function safeRound(n) {
    return Math.round((n + Number.EPSILON) * 1000) / 1000;
}
function computeCategory(score) {
    var _a;
    var keys = Object.keys(score);
    var computedScore = 0;
    for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
        var statKey = keys_1[_i];
        computedScore += safeRound(((_a = score[statKey]) === null || _a === void 0 ? void 0 : _a.value) || 0);
    }
    return computedScore;
}
exports.computeCategory = computeCategory;
function computeAthleteScore(score) {
    var categories = Object.keys(score);
    var computedScore = 0;
    for (var _i = 0, categories_1 = categories; _i < categories_1.length; _i++) {
        var category = categories_1[_i];
        var value = score[category];
        computedScore += computeCategory(value);
    }
    return Math.max(0, safeRound(computedScore));
}
exports.computeAthleteScore = computeAthleteScore;
function computeModifiers(_a, modifierConfig) {
    var _b;
    var nftEdition = _a.nftEdition, nftRarity = _a.nftRarity, entries = _a.entries, isCaptain = _a.isCaptain, virtualMatchResultModifier = _a.virtualMatchResultModifier, gameMode = _a.gameMode, stackingPenalty = _a.stackingPenalty;
    var conditionModifierValue = gameMode !== gameview_1.GameMode.LEAGUES
        ? computeConditionModifier(modifierConfig, {
            nftRarity: nftRarity,
            entries: entries,
            nftEdition: nftEdition,
        })
        : 0;
    var rarityModifierValue = 0;
    var editionModifierValue = gameMode !== gameview_1.GameMode.LEAGUES
        ? ((_b = modifierConfig.editions.find(function (_a) {
            var edition = _a.edition;
            return edition === nftEdition;
        })) === null || _b === void 0 ? void 0 : _b.modifierValue) || 0
        : 0;
    var captainModifierValue = isCaptain ? modifierConfig.team.captain : 0;
    var virtualMatchResultModifierValue = virtualMatchResultModifier && modifierConfig.vgw
        ? modifierConfig.vgw[virtualMatchResultModifier]
        : 0;
    var totalModifierValue = conditionModifierValue +
        rarityModifierValue +
        editionModifierValue +
        captainModifierValue +
        virtualMatchResultModifierValue -
        (stackingPenalty !== null && stackingPenalty !== void 0 ? stackingPenalty : 0);
    return {
        conditionModifierValue: conditionModifierValue,
        rarityModifierValue: rarityModifierValue,
        editionModifierValue: editionModifierValue,
        captainModifierValue: captainModifierValue,
        virtualMatchResultModifierValue: virtualMatchResultModifierValue,
        stackingPenalty: stackingPenalty !== null && stackingPenalty !== void 0 ? stackingPenalty : 0,
        totalModifierValue: totalModifierValue,
    };
}
exports.computeModifiers = computeModifiers;
function computeConditionModifier(modifierConfig, _a) {
    var nftEdition = _a.nftEdition, entries = _a.entries, nftRarity = _a.nftRarity;
    return (0, conditionBonus_1.getConditionModifier)((0, conditionBonus_1.getConditionBonus)(nftRarity, entries, nftEdition), modifierConfig);
}
exports.computeConditionModifier = computeConditionModifier;
function computePlayerScore(_a, modifierConfig) {
    var athleteScore = _a.athleteScore, params = __rest(_a, ["athleteScore"]);
    var computedModifiers = computeModifiers(params, modifierConfig);
    var score = (athleteScore * computedModifiers.totalModifierValue) / 100 + athleteScore;
    return __assign(__assign({}, computedModifiers), { score: safeRound(score), displayScore: formatScore(score) });
}
exports.computePlayerScore = computePlayerScore;
function formatScore(score) {
    return String(Math.round(score));
}
exports.formatScore = formatScore;
