import { createTheme } from './config';
import { IColorPalettes, IThemeConfig } from './types';

const SPACING_CONFIG: IThemeConfig['spacing'] = {
  8: '8px',
  12: '12px',
  16: '16px',
  24: '24px',
  32: '32px',
  40: '40px',
  48: '48px',
  56: '56px',
  64: '64px',
  80: '80px',
};

export const BREAKPOINTS_CONFIG: IThemeConfig['breakpoints'] = {
  xs: 375,
  /**
   * [M]obile
   */
  sm: 600,
  /**
   * [T]ablet
   */
  md: 900,
  /**
   * [D]esktop
   */
  lg: 1440,
  xl: 1920,
};

const createDarkColorPalettes = (
  accentPalette: IColorPalettes['accent']
): IThemeConfig['color'] => ({
  primary: {
    '100': '#030A1C',
    '95': '#061336',
    '90': '#091B4D',
    '85': '#0F3CB0',
    '80': '#0B37AA',
    '75': '#1040C0',
    '70': '#1547CC',
    '65': '#164FE9',
    '60': '#6879A8',
    '55': '#7484AF',
    '50': '#818FB6',
    '45': '#8E9BBE',
    '40': '#9BA6C5',
    '35': '#A8B2CD',
    '30': '#B4BDD4',
    '25': '#C1C9DC',
    '20': '#CED4E3',
    '15': '#DBDFEB',
    '10': '#E8EBF2',
    '5': '#EFF1F6',
    '0': '#FFFFFF',
    'Dark 90%': 'rgba(8, 8, 8, 0.9)',
    'Dark 80%': 'rgba(8, 8, 8, 0.8)',
    'Dark 70%': 'rgba(8, 8, 8, 0.7)',
    'Dark 60%': 'rgba(8, 8, 8, 0.6)',
    'Dark 50%': 'rgba(8, 8, 8, 0.5)',
    'Dark 40%': 'rgba(8, 8, 8, 0.4)',
    'Dark 30%': 'rgba(8, 8, 8, 0.3)',
    'Dark 20%': 'rgba(8, 8, 8, 0.2)',
    'Dark 10%': 'rgba(8, 8, 8, 0.1)',
    'Dark 5%': 'rgba(8, 8, 8, 0.05)',
    'Light 90%': 'rgba(255, 255, 255, 0.9)',
    'Light 80%': 'rgba(255, 255, 255, 0.8)',
    'Light 70%': 'rgba(255, 255, 255, 0.7)',
    'Light 60%': 'rgba(255, 255, 255, 0.6)',
    'Light 50%': 'rgba(255, 255, 255, 0.5)',
    'Light 40%': 'rgba(255, 255, 255, 0.4)',
    'Light 30%': 'rgba(255, 255, 255, 0.3)',
    'Light 20%': 'rgba(255, 255, 255, 0.2)',
    'Light 10%': 'rgba(255, 255, 255, 0.1)',
    'Light 5%': 'rgba(255, 255, 255, 0.05)',
  },
  accent: accentPalette,
  success: {
    '90': '#00120C',
    '80': '#012819',
    '70': '#015032',
    '60': '#02794B',
    '50': '#02935C',
    '40': '#03DD8A',
    '30': '#36FCB0',
    '20': '#AFFEDF',
    '10': '#EBFFF7',
    '90%': 'rgba(2, 179, 92, 0.9)',
    '80%': 'rgba(2, 179, 92, 0.8)',
    '70%': 'rgba(2, 179, 92, 0.7)',
    '60%': 'rgba(2, 179, 92, 0.6)',
    '50%': 'rgba(2, 179, 92, 0.5)',
    '40%': 'rgba(2, 179, 92, 0.4)',
    '30%': 'rgba(2, 179, 92, 0.3)',
    '20%': 'rgba(2, 179, 92, 0.2)',
    '10%': 'rgba(2, 179, 92, 0.1)',
    '5%': 'rgba(2, 179, 92, 0.05)',
  },
  warning: {
    '90': '#130901',
    '80': '#391C04',
    '70': '#723808',
    '60': '#BF5D0D',
    '50': '#F08022',
    '40': '#F39B53',
    '30': '#F7BC8D',
    '20': '#FAD3B3',
    '10': '#FEF4EC',
    '90%': 'rgba(240, 128, 32, 0.9)',
    '80%': 'rgba(240, 128, 32, 0.8)',
    '70%': 'rgba(240, 128, 32, 0.7)',
    '60%': 'rgba(240, 128, 32, 0.6)',
    '50%': 'rgba(240, 128, 32, 0.5)',
    '40%': 'rgba(240, 128, 32, 0.4)',
    '30%': 'rgba(240, 128, 32, 0.3)',
    '20%': 'rgba(240, 128, 32, 0.2)',
    '10%': 'rgba(240, 128, 32, 0.1)',
    '5%': 'rgba(240, 128, 32, 0.05)',
  },
  error: {
    '90': '#120303',
    '80': '#470C0B',
    '70': '#8E1715',
    '60': '#D52320',
    '50': '#E23F3C',
    '40': '#E7625F',
    '30': '#EF9695',
    '20': '#F7CBCA',
    '10': '#FCEEED',
    '90%': 'rgba(226, 63, 60, 0.9)',
    '80%': 'rgba(226, 63, 60, 0.8)',
    '70%': 'rgba(226, 63, 60, 0.7)',
    '60%': 'rgba(226, 63, 60, 0.6)',
    '50%': 'rgba(226, 63, 60, 0.5)',
    '40%': 'rgba(226, 63, 60, 0.4)',
    '30%': 'rgba(226, 63, 60, 0.3)',
    '20%': 'rgba(226, 63, 60, 0.2)',
    '10%': 'rgba(226, 63, 60, 0.1)',
    '5%': 'rgba(226, 63, 60, 0.05)',
  },
  info: {
    '90': '#081421',
    '80': '#0B1F32',
    '70': '#173D64',
    '60': '#1E5285',
    '50': '#296EB4',
    '40': '#5999D9',
    '30': '#7AADE1',
    '20': '#ACCCEC',
    '10': '#DEEBF7',
    '90%': 'rgba(41, 110, 180, 0.9)',
    '80%': 'rgba(41, 110, 180, 0.8)',
    '70%': 'rgba(41, 110, 180, 0.7)',
    '60%': 'rgba(41, 110, 180, 0.6)',
    '50%': 'rgba(41, 110, 180, 0.5)',
    '40%': 'rgba(41, 110, 180, 0.4)',
    '30%': 'rgba(41, 110, 180, 0.3)',
    '20%': 'rgba(41, 110, 180, 0.2)',
    '10%': 'rgba(41, 110, 180, 0.1)',
    '5%': 'rgba(41, 110, 180, 0.05)',
  },
  virtual: {
    '90': '#241159',
    '80': '#3D1F93',
    '70': '#562AD5',
    '60': '#775CFF',
    '50': '#9580FF',
    '40': '#AA99FF',
    '30': '#BBADFF',
    '20': '#DDD6FF',
    '10': '#EEEBFF',
    '90%': 'rgba(149, 128, 255, 0.9)',
    '80%': 'rgba(149, 128, 255, 0.8)',
    '70%': 'rgba(149, 128, 255, 0.7)',
    '60%': 'rgba(149, 128, 255, 0.6)',
    '50%': 'rgba(149, 128, 255, 0.5)',
    '40%': 'rgba(149, 128, 255, 0.4)',
    '30%': 'rgba(149, 128, 255, 0.3)',
    '20%': 'rgba(149, 128, 255, 0.2)',
    '10%': 'rgba(149, 128, 255, 0.1)',
    '5%': 'rgba(149, 128, 255, 0.05)',
  },
});

/**
 * Source of truth for design system
 */
export const FOOTBALL_THEME = createTheme({
  color: createDarkColorPalettes({
    '90': '#4D3200',
    '80': '#805400',
    '70': '#E59700',
    '60': '#FFB11A',
    '50': '#FFC34F',
    '40': '#FFD480',
    '30': '#FFE5B2',
    '20': '#FFF6E5',
    '10': '#FFFCF5',
    '90%': 'rgba(218, 251, 85, 0.9)',
    '80%': 'rgba(218, 251, 85, 0.8)',
    '70%': 'rgba(218, 251, 85, 0.7)',
    '60%': 'rgba(218, 251, 85, 0.6)',
    '50%': 'rgba(218, 251, 85, 0.5)',
    '40%': 'rgba(218, 251, 85, 0.4)',
    '30%': 'rgba(218, 251, 85, 0.3)',
    '20%': 'rgba(218, 251, 85, 0.2)',
    '10%': 'rgba(218, 251, 85, 0.1)',
    '5%': 'rgba(218, 251, 85, 0.05)',
  }),
  breakpoints: BREAKPOINTS_CONFIG,
  spacing: SPACING_CONFIG,
});

export const BASKETBALL_THEME = createTheme({
  color: createDarkColorPalettes({
    '90': '#291000',
    '80': '#8F3700',
    '70': '#F55E00',
    '60': '#FF751F',
    '50': '#FF873C',
    '40': '#FFA770',
    '30': '#FFCDAD',
    '20': '#FFE6D6',
    '10': '#FFF2EB',
    '90%': 'rgba(255, 135, 60, 0.9)',
    '80%': 'rgba(255, 135, 60, 0.8)',
    '70%': 'rgba(255, 135, 60, 0.7)',
    '60%': 'rgba(255, 135, 60, 0.6)',
    '50%': 'rgba(255, 135, 60, 0.5)',
    '40%': 'rgba(255, 135, 60, 0.4)',
    '30%': 'rgba(255, 135, 60, 0.3)',
    '20%': 'rgba(255, 135, 60, 0.2)',
    '10%': 'rgba(255, 135, 60, 0.1)',
    '5%': 'rgba(255, 135, 60, 0.05)',
  }),
  breakpoints: BREAKPOINTS_CONFIG,
  spacing: SPACING_CONFIG,
});

export const LFP_THEME = createTheme({
  color: {
    primary: {
      '0': '#091C3E',
      '5': '#0A1E42',
      '10': '#0A2047',
      '15': '#0B234D',
      '20': '#0B234D',
      '25': '#0C2552',
      '30': '#0D2757',
      '35': '#0D295C',
      '40': '#0E2C61',
      '45': '#0F2E66',
      '50': '#10306B',
      '55': '#808080',
      '60': '#999999',
      '65': '#B2B2B2',
      '70': '#CCCCCC',
      '75': '#E5E5E5',
      '80': '#EBEBEB',
      '85': '#F0F0F0',
      '90': '#F5F5F5',
      '95': '#FAFAFA',
      '100': '#FFFFFF',
      'Dark 90%': 'rgba(255, 255, 255, 0.9)',
      'Dark 80%': 'rgba(255, 255, 255, 0.8)',
      'Dark 70%': 'rgba(255, 255, 255, 0.7)',
      'Dark 60%': 'rgba(255, 255, 255, 0.6)',
      'Dark 50%': 'rgba(255, 255, 255, 0.5)',
      'Dark 40%': 'rgba(255, 255, 255, 0.4)',
      'Dark 30%': 'rgba(255, 255, 255, 0.3)',
      'Dark 20%': 'rgba(255, 255, 255, 0.2)',
      'Dark 10%': 'rgba(255, 255, 255, 0.1)',
      'Dark 5%': 'rgba(255, 255, 255, 0.05)',
      'Light 90%': 'rgba(0, 0, 0, 0.9)',
      'Light 80%': 'rgba(0, 0, 0, 0.8)',
      'Light 70%': 'rgba(0, 0, 0, 0.7)',
      'Light 60%': 'rgba(0, 0, 0, 0.6)',
      'Light 50%': 'rgba(0, 0, 0, 0.5)',
      'Light 40%': 'rgba(0, 0, 0, 0.4)',
      'Light 30%': 'rgba(0, 0, 0, 0.3)',
      'Light 20%': 'rgba(0, 0, 0, 0.2)',
      'Light 10%': 'rgba(0, 0, 0, 0.1)',
      'Light 5%': 'rgba(0, 0, 0, 0.05)',
    },
    accent: {
      '90': '#394409',
      '80': '#9ABD08',
      '70': '#ABD108',
      '60': '#BBE509',
      '50': '#CDFB0A',
      '40': '#D4F939',
      '30': '#E1FF63',
      '20': '#EEFFA5',
      '10': '#F4FFC8',
      '90%': 'rgba(205, 251, 10, 0.9)',
      '80%': 'rgba(205, 251, 10, 0.8)',
      '70%': 'rgba(205, 251, 10, 0.7)',
      '60%': 'rgba(205, 251, 10, 0.6)',
      '50%': 'rgba(205, 251, 10, 0.5)',
      '40%': 'rgba(205, 251, 10, 0.4)',
      '30%': 'rgba(205, 251, 10, 0.3)',
      '20%': 'rgba(205, 251, 10, 0.2)',
      '10%': 'rgba(205, 251, 10, 0.1)',
      '5%': 'rgba(205, 251, 10, 0.05)',
    },
    success: {
      '90': '#061200',
      '80': '#0E2801',
      '70': '#1B5001',
      '60': '#2A7902',
      '50': '#329302',
      '40': '#4CDD03',
      '30': '#78FC36',
      '20': '#C9FEAF',
      '10': '#F2FFEB',
      '90%': 'rgba(50, 147, 2, 0.9)',
      '80%': 'rgba(50, 147, 2, 0.8)',
      '70%': 'rgba(50, 147, 2, 0.7)',
      '60%': 'rgba(50, 147, 2, 0.6)',
      '50%': 'rgba(50, 147, 2, 0.5)',
      '40%': 'rgba(50, 147, 2, 0.4)',
      '30%': 'rgba(50, 147, 2, 0.3)',
      '20%': 'rgba(50, 147, 2, 0.2)',
      '10%': 'rgba(50, 147, 2, 0.1)',
      '5%': 'rgba(50, 147, 2, 0.05)',
    },
    warning: {
      '90': '#4B1608',
      '80': '#B43313',
      '70': '#F07D22',
      '60': '#F09122',
      '50': '#F0A522',
      '40': '#F9BA4C',
      '30': '#FFD58B',
      '20': '#FFE8C0',
      '10': '#FFF4E0',
      '90%': 'rgba(240, 165, 34 , 0.9)',
      '80%': 'rgba(240, 165, 34 , 0.8)',
      '70%': 'rgba(240, 165, 34 , 0.7)',
      '60%': 'rgba(240, 165, 34 , 0.6)',
      '50%': 'rgba(240, 165, 34 , 0.5)',
      '40%': 'rgba(240, 165, 34 , 0.4)',
      '30%': 'rgba(240, 165, 34 , 0.3)',
      '20%': 'rgba(240, 165, 34 , 0.2)',
      '10%': 'rgba(240, 165, 34 , 0.1)',
      '5%': 'rgba(240, 165, 34 , 0.05)',
    },
    error: {
      '90': '#330000',
      '80': '#660000',
      '70': '#CC0000',
      '60': '#FF0000',
      '50': '#FF1111',
      '40': '#FF3333',
      '30': '#FF6666',
      '20': '#FF9999',
      '10': '#FFCCCC',
      '90%': 'rgba(255, 17, 17, 0.9)',
      '80%': 'rgba(255, 17, 17, 0.8)',
      '70%': 'rgba(255, 17, 17, 0.7)',
      '60%': 'rgba(255, 17, 17, 0.6)',
      '50%': 'rgba(255, 17, 17, 0.5)',
      '40%': 'rgba(255, 17, 17, 0.4)',
      '30%': 'rgba(255, 17, 17, 0.3)',
      '20%': 'rgba(255, 17, 17, 0.2)',
      '10%': 'rgba(255, 17, 17, 0.1)',
      '5%': 'rgba(255, 17, 17, 0.05)',
    },
    info: {
      '90': '#004582',
      '80': '#0057A4',
      '70': '#0066C1',
      '60': '#006BCA',
      '50': '#0087FF',
      '40': '#2096FF',
      '30': '#1792FF',
      '20': '#73BDFF',
      '10': '#D4EBFF',
      '90%': 'rgba(0, 135, 255, 0.9)',
      '80%': 'rgba(0, 135, 255, 0.8)',
      '70%': 'rgba(0, 135, 255, 0.7)',
      '60%': 'rgba(0, 135, 255, 0.6)',
      '50%': 'rgba(0, 135, 255, 0.5)',
      '40%': 'rgba(0, 135, 255, 0.4)',
      '30%': 'rgba(0, 135, 255, 0.3)',
      '20%': 'rgba(0, 135, 255, 0.2)',
      '10%': 'rgba(0, 135, 255, 0.1)',
      '5%': 'rgba(0, 135, 255, 0.05)',
    },
    virtual: {
      '90': '#241159',
      '80': '#3D1F93',
      '70': '#562AD5',
      '60': '#775CFF',
      '50': '#9580FF',
      '40': '#AA99FF',
      '30': '#BBADFF',
      '20': '#DDD6FF',
      '10': '#EEEBFF',
      '90%': 'rgba(149, 128, 255, 0.9)',
      '80%': 'rgba(149, 128, 255, 0.8)',
      '70%': 'rgba(149, 128, 255, 0.7)',
      '60%': 'rgba(149, 128, 255, 0.6)',
      '50%': 'rgba(149, 128, 255, 0.5)',
      '40%': 'rgba(149, 128, 255, 0.4)',
      '30%': 'rgba(149, 128, 255, 0.3)',
      '20%': 'rgba(149, 128, 255, 0.2)',
      '10%': 'rgba(149, 128, 255, 0.1)',
      '5%': 'rgba(149, 128, 255, 0.05)',
    },
  },
  breakpoints: BREAKPOINTS_CONFIG,
  spacing: SPACING_CONFIG,
});
