interface IPayload {
  person?: {
    id: string;
    username?: string;
  };
}

interface IConfig {
  payload: IPayload;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    Rollbar: {
      error: (e: Error, ...params: unknown[]) => undefined;
      configure: (c: IConfig) => undefined;
    };
  }
}

export const error = (e: Error, ...params: unknown[]) => {
  // eslint-disable-next-line no-console
  console.error(e, ...params);
  return e;
};
