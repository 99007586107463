import { Brand } from 'ultimate-league-common';

import { IColorPalettes, TColorValue } from '../../../theme';
import type { IChipProps } from './Chip';

const getTextColor = (colors: IColorPalettes, invert?: boolean) =>
  Brand.switchBrand({
    UC: colors.primary[invert ? '100' : '0'],
    LFP: colors.primary[invert ? '0' : '100'],
  });

const getDisabledFilledColors = (colors: IColorPalettes) =>
  Brand.switchBrand({
    UC: {
      text: colors.primary['50'],
      background: colors.primary['80'],
    },
    LFP: {
      text: colors.primary['75'],
      background: colors.primary['90'],
    },
  });

/**
 * Translate IChipProps (variant and color) to actual text, background and border color
 */
export const computeColors = (
  colors: IColorPalettes
): Record<
  Required<IChipProps>['color'],
  Record<
    IChipProps['variant'],
    Record<
      'default' | 'disabled' | 'hover' | 'pressed' | 'focused',
      {
        text: TColorValue;
        border?: TColorValue;
        background?: TColorValue;
      }
    >
  >
> => ({
  default: {
    filled: {
      default: {
        text: colors.primary['100'],
        background: colors.primary['0'],
      },
      hover: {
        text: colors.primary['100'],
        background: colors.primary['10'],
      },
      focused: {
        text: colors.primary['100'],
        background: colors.primary['10'],
      },
      pressed: {
        text: colors.primary['100'],
        background: colors.primary['20'],
      },
      disabled: getDisabledFilledColors(colors),
    },
    tonal: {
      default: {
        text: colors.primary['0'],
        background: colors.primary['Light 10%'],
      },
      hover: {
        text: colors.primary['0'],
        background: colors.primary['Light 20%'],
      },
      focused: {
        text: colors.primary['0'],
        background: colors.primary['Light 20%'],
      },
      pressed: {
        text: colors.primary['0'],
        background: colors.primary['Light 30%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
        background: colors.primary['Light 5%'],
      },
    },
    outlined: {
      default: {
        text: colors.primary['0'],
        border: colors.primary['0'],
      },
      hover: {
        text: colors.primary['0'],
        border: colors.primary['0'],
        background: colors.primary['Light 10%'],
      },
      focused: {
        text: colors.primary['0'],
        border: colors.primary['0'],
        background: colors.primary['Light 10%'],
      },
      pressed: {
        text: colors.primary['0'],
        border: colors.primary['0'],
        background: colors.primary['Light 20%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
        border: colors.primary['Light 5%'],
      },
    },
    text: {
      default: {
        text: colors.primary['0'],
      },
      hover: {
        text: colors.primary['0'],
        background: colors.primary['Light 10%'],
      },
      focused: {
        text: colors.primary['0'],
        background: colors.primary['Light 10%'],
      },
      pressed: {
        text: colors.primary['0'],
        background: colors.primary['Light 20%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
      },
    },
  },
  subtle: {
    filled: {
      default: {
        text: colors.primary['Dark 40%'],
        background: colors.primary['0'],
      },
      hover: {
        text: colors.primary['Dark 40%'],
        background: colors.primary['5'],
      },
      focused: {
        text: colors.primary['Dark 40%'],
        background: colors.primary['5'],
      },
      pressed: {
        text: colors.primary['Dark 40%'],
        background: colors.primary['10'],
      },
      disabled: getDisabledFilledColors(colors),
    },
    tonal: {
      default: {
        text: colors.primary['Light 40%'],
        background: colors.primary['Light 5%'],
      },
      hover: {
        text: colors.primary['Light 40%'],
        background: colors.primary['Light 10%'],
      },
      focused: {
        text: colors.primary['Light 40%'],
        background: colors.primary['Light 10%'],
      },
      pressed: {
        text: colors.primary['Light 40%'],
        background: colors.primary['Light 20%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
        background: colors.primary['Light 5%'],
      },
    },
    outlined: {
      default: {
        text: colors.primary['Light 40%'],
        border: colors.primary['Light 5%'],
      },
      hover: {
        text: colors.primary['Light 40%'],
        border: colors.primary['Light 10%'],
        background: colors.primary['Light 5%'],
      },
      focused: {
        text: colors.primary['Light 40%'],
        border: colors.primary['Light 10%'],
        background: colors.primary['Light 5%'],
      },
      pressed: {
        text: colors.primary['Light 40%'],
        border: colors.primary['Light 10%'],
        background: colors.primary['Light 10%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
        border: colors.primary['Light 5%'],
      },
    },
    text: {
      default: {
        text: colors.primary['Light 40%'],
      },
      hover: {
        text: colors.primary['Light 40%'],
        background: colors.primary['Light 5%'],
      },
      focused: {
        text: colors.primary['Light 40%'],
        background: colors.primary['Light 5%'],
      },
      pressed: {
        text: colors.primary['Light 40%'],
        background: colors.primary['Light 10%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
      },
    },
  },
  dark: {
    filled: {
      default: {
        text: colors.primary['0'],
        background: colors.primary['100'],
      },
      hover: {
        text: colors.primary['0'],
        background: colors.primary['90'],
      },
      focused: {
        text: colors.primary['0'],
        background: colors.primary['90'],
      },
      pressed: {
        text: colors.primary['0'],
        background: colors.primary['85'],
      },
      disabled: getDisabledFilledColors(colors),
    },
    tonal: {
      default: {
        text: colors.primary['100'],
        background: colors.primary['Dark 20%'],
      },
      hover: {
        text: colors.primary['100'],
        background: colors.primary['Dark 30%'],
      },
      focused: {
        text: colors.primary['100'],
        background: colors.primary['Dark 30%'],
      },
      pressed: {
        text: colors.primary['100'],
        background: colors.primary['Dark 40%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
        background: colors.primary['Light 5%'],
      },
    },
    outlined: {
      default: {
        text: colors.primary['100'],
        border: colors.primary['100'],
      },
      hover: {
        text: colors.primary['100'],
        border: colors.primary['100'],
        background: colors.primary['Dark 10%'],
      },
      focused: {
        text: colors.primary['100'],
        border: colors.primary['100'],
        background: colors.primary['Dark 10%'],
      },
      pressed: {
        text: colors.primary['100'],
        border: colors.primary['100'],
        background: colors.primary['Dark 20%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
        border: colors.primary['Light 5%'],
      },
    },
    text: {
      default: {
        text: colors.primary['100'],
      },
      hover: {
        text: colors.primary['100'],
        background: colors.primary['Dark 10%'],
      },
      focused: {
        text: colors.primary['100'],
        background: colors.primary['Dark 10%'],
      },
      pressed: {
        text: colors.primary['100'],
        background: colors.primary['Dark 20%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
      },
    },
  },
  accent: {
    filled: {
      default: {
        text: getTextColor(colors, true),
        background: colors.accent['50'],
      },
      hover: {
        text: getTextColor(colors, true),
        background: colors.accent['60'],
      },
      focused: {
        text: getTextColor(colors, true),
        background: colors.accent['60'],
      },
      pressed: {
        text: getTextColor(colors, true),
        background: colors.accent['70'],
      },
      disabled: getDisabledFilledColors(colors),
    },
    tonal: {
      default: {
        text: colors.accent['50'],
        background: colors.accent['20%'],
      },
      hover: {
        text: colors.accent['50'],
        background: colors.accent['30%'],
      },
      focused: {
        text: colors.accent['50'],
        background: colors.accent['30%'],
      },
      pressed: {
        text: colors.accent['50'],
        background: colors.accent['50%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
        background: colors.primary['Light 5%'],
      },
    },
    outlined: {
      default: {
        text: colors.accent['50'],
        border: colors.accent['50'],
      },
      hover: {
        text: colors.accent['50'],
        border: colors.accent['50'],
        background: colors.accent['10%'],
      },
      focused: {
        text: colors.accent['50'],
        border: colors.accent['50'],
        background: colors.accent['10%'],
      },
      pressed: {
        text: colors.accent['50'],
        border: colors.accent['50'],
        background: colors.accent['20%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
        border: colors.primary['Light 5%'],
      },
    },
    text: {
      default: {
        text: colors.accent['50'],
      },
      hover: {
        text: colors.accent['50'],
        background: colors.accent['10%'],
      },
      focused: {
        text: colors.accent['50'],
        background: colors.accent['10%'],
      },
      pressed: {
        text: colors.accent['50'],
        background: colors.accent['20%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
      },
    },
  },
  warning: {
    filled: {
      default: {
        text: getTextColor(colors),
        background: colors.warning['50'],
      },
      hover: {
        text: getTextColor(colors),
        background: colors.warning['60'],
      },
      focused: {
        text: getTextColor(colors),
        background: colors.warning['60'],
      },
      pressed: {
        text: getTextColor(colors),
        background: colors.warning['70'],
      },
      disabled: getDisabledFilledColors(colors),
    },
    tonal: {
      default: {
        text: colors.warning['50'],
        background: colors.warning['20%'],
      },
      hover: {
        text: colors.warning['50'],
        background: colors.warning['30%'],
      },
      focused: {
        text: colors.warning['50'],
        background: colors.warning['30%'],
      },
      pressed: {
        text: colors.warning['50'],
        background: colors.warning['50%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
        background: colors.primary['Light 5%'],
      },
    },
    outlined: {
      default: {
        text: colors.warning['50'],
        border: colors.warning['50'],
      },
      hover: {
        text: colors.warning['50'],
        border: colors.warning['50'],
        background: colors.warning['10%'],
      },
      focused: {
        text: colors.warning['50'],
        border: colors.warning['50'],
        background: colors.warning['10%'],
      },
      pressed: {
        text: colors.warning['50'],
        border: colors.warning['50'],
        background: colors.warning['20%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
        border: colors.primary['Light 5%'],
      },
    },
    text: {
      default: {
        text: colors.warning['50'],
      },
      hover: {
        text: colors.warning['50'],
        background: colors.warning['10%'],
      },
      focused: {
        text: colors.warning['50'],
        background: colors.warning['10%'],
      },
      pressed: {
        text: colors.warning['50'],
        background: colors.warning['20%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
      },
    },
  },
  info: {
    filled: {
      default: {
        text: getTextColor(colors),
        background: colors.info['50'],
      },
      hover: {
        text: getTextColor(colors),
        background: colors.info['60'],
      },
      focused: {
        text: getTextColor(colors),
        background: colors.info['60'],
      },
      pressed: {
        text: getTextColor(colors),
        background: colors.info['70'],
      },
      disabled: getDisabledFilledColors(colors),
    },
    tonal: {
      default: {
        text: colors.info['50'],
        background: colors.info['20%'],
      },
      hover: {
        text: colors.info['50'],
        background: colors.info['30%'],
      },
      focused: {
        text: colors.info['50'],
        background: colors.info['30%'],
      },
      pressed: {
        text: colors.info['50'],
        background: colors.info['50%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
        background: colors.primary['Light 5%'],
      },
    },
    outlined: {
      default: {
        text: colors.info['50'],
        border: colors.info['50'],
      },
      hover: {
        text: colors.info['50'],
        border: colors.info['50'],
        background: colors.info['10%'],
      },
      focused: {
        text: colors.info['50'],
        border: colors.info['50'],
        background: colors.info['10%'],
      },
      pressed: {
        text: colors.info['50'],
        border: colors.info['50'],
        background: colors.info['20%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
        border: colors.primary['Light 5%'],
      },
    },
    text: {
      default: {
        text: colors.info['50'],
      },
      hover: {
        text: colors.info['50'],
        background: colors.info['10%'],
      },
      focused: {
        text: colors.info['50'],
        background: colors.info['10%'],
      },
      pressed: {
        text: colors.info['50'],
        background: colors.info['20%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
      },
    },
  },
  success: {
    filled: {
      default: {
        text: getTextColor(colors),
        background: colors.success['50'],
      },
      hover: {
        text: getTextColor(colors),
        background: colors.success['60'],
      },
      focused: {
        text: getTextColor(colors),
        background: colors.success['60'],
      },
      pressed: {
        text: getTextColor(colors),
        background: colors.success['70'],
      },
      disabled: getDisabledFilledColors(colors),
    },
    tonal: {
      default: {
        text: colors.success['50'],
        background: colors.success['20%'],
      },
      hover: {
        text: colors.success['50'],
        background: colors.success['30%'],
      },
      focused: {
        text: colors.success['50'],
        background: colors.success['30%'],
      },
      pressed: {
        text: colors.success['50'],
        background: colors.success['50%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
        background: colors.primary['Light 5%'],
      },
    },
    outlined: {
      default: {
        text: colors.success['50'],
        border: colors.success['50'],
      },
      hover: {
        text: colors.success['50'],
        border: colors.success['50'],
        background: colors.success['10%'],
      },
      focused: {
        text: colors.success['50'],
        border: colors.success['50'],
        background: colors.success['10%'],
      },
      pressed: {
        text: colors.success['50'],
        border: colors.success['50'],
        background: colors.success['20%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
        border: colors.primary['Light 5%'],
      },
    },
    text: {
      default: {
        text: colors.success['50'],
      },
      hover: {
        text: colors.success['50'],
        background: colors.success['10%'],
      },
      focused: {
        text: colors.success['50'],
        background: colors.success['10%'],
      },
      pressed: {
        text: colors.success['50'],
        background: colors.success['20%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
      },
    },
  },
  error: {
    filled: {
      default: {
        text: getTextColor(colors),
        background: colors.error['50'],
      },
      hover: {
        text: getTextColor(colors),
        background: colors.error['60'],
      },
      focused: {
        text: getTextColor(colors),
        background: colors.error['60'],
      },
      pressed: {
        text: getTextColor(colors),
        background: colors.error['70'],
      },
      disabled: getDisabledFilledColors(colors),
    },
    tonal: {
      default: {
        text: colors.error['50'],
        background: colors.error['20%'],
      },
      hover: {
        text: colors.error['50'],
        background: colors.error['30%'],
      },
      focused: {
        text: colors.error['50'],
        background: colors.error['30%'],
      },
      pressed: {
        text: colors.error['50'],
        background: colors.error['50%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
        background: colors.primary['Light 5%'],
      },
    },
    outlined: {
      default: {
        text: colors.error['50'],
        border: colors.error['50'],
      },
      hover: {
        text: colors.error['50'],
        border: colors.error['50'],
        background: colors.error['10%'],
      },
      focused: {
        text: colors.error['50'],
        border: colors.error['50'],
        background: colors.error['10%'],
      },
      pressed: {
        text: colors.error['50'],
        border: colors.error['50'],
        background: colors.error['20%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
        border: colors.primary['Light 5%'],
      },
    },
    text: {
      default: {
        text: colors.error['50'],
      },
      hover: {
        text: colors.error['50'],
        background: colors.error['10%'],
      },
      focused: {
        text: colors.error['50'],
        background: colors.error['10%'],
      },
      pressed: {
        text: colors.error['50'],
        background: colors.error['20%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
      },
    },
  },
  virtual: {
    filled: {
      default: {
        text: getTextColor(colors),
        background: colors.virtual['60'],
      },
      hover: {
        text: getTextColor(colors),
        background: colors.virtual['70'],
      },
      focused: {
        text: getTextColor(colors),
        background: colors.virtual['70'],
      },
      pressed: {
        text: getTextColor(colors),
        background: colors.virtual['80'],
      },
      disabled: getDisabledFilledColors(colors),
    },
    tonal: {
      default: {
        text: colors.virtual['60'],
        background: colors.virtual['20%'],
      },
      hover: {
        text: colors.virtual['60'],
        background: colors.virtual['30%'],
      },
      focused: {
        text: colors.virtual['60'],
        background: colors.virtual['30%'],
      },
      pressed: {
        text: colors.virtual['60'],
        background: colors.virtual['50%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
        background: colors.primary['Light 5%'],
      },
    },
    outlined: {
      default: {
        text: colors.virtual['60'],
        border: colors.virtual['60'],
      },
      hover: {
        text: colors.virtual['60'],
        border: colors.virtual['60'],
        background: colors.virtual['10%'],
      },
      focused: {
        text: colors.virtual['60'],
        border: colors.virtual['60'],
        background: colors.virtual['10%'],
      },
      pressed: {
        text: colors.virtual['60'],
        border: colors.virtual['60'],
        background: colors.virtual['20%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
        border: colors.primary['Light 5%'],
      },
    },
    text: {
      default: {
        text: colors.virtual['60'],
      },
      hover: {
        text: colors.virtual['60'],
        background: colors.virtual['10%'],
      },
      focused: {
        text: colors.virtual['60'],
        background: colors.virtual['10%'],
      },
      pressed: {
        text: colors.virtual['60'],
        background: colors.virtual['20%'],
      },
      disabled: {
        text: colors.primary['Light 20%'],
      },
    },
  },
});
