"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MIN_WITHDRAWAL_AMOUNT_FIAT = exports.withdrawToken = exports.token = exports.weiAmountOptional = exports.weiAmount = void 0;
var technical_1 = require("../../technical");
var validation_1 = require("../validation");
var type_1 = require("./type");
exports.weiAmount = {
    numericality: {
        greaterThan: 0,
    },
    presence: {
        allowEmpty: false,
    },
};
exports.weiAmountOptional = {
    numericality: {
        greaterThan: 0,
    },
    presence: false,
};
exports.token = {
    inclusion: (0, technical_1.enumToArray)(type_1.Token),
    presence: {
        allowEmpty: false,
    },
};
exports.withdrawToken = {
    blockchain: validation_1.blockchain,
    to: technical_1.address,
    weiAmount: exports.weiAmount,
    token: exports.token,
};
exports.MIN_WITHDRAWAL_AMOUNT_FIAT = 5;
