"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrandConfig = void 0;
var type_1 = require("./NFT-card/type");
exports.BrandConfig = process.env.NODE_ENV === 'test'
    ? {
        activatedBlockchains: ['polygon', 'bsc'],
        distributeBlockchain: 'bsc',
        mintBlockchain: 'bsc',
        paymentPriority: ['polygon', 'bsc'],
        activatedRarities: [
            type_1.RarityLevel.FUNGIBLE,
            type_1.RarityLevel.RARITY_3,
            type_1.RarityLevel.RARITY_2,
        ],
        enableConditionBonus: false,
        displayCardModifiers: false,
    }
    : {
        activatedBlockchains: ['polygon', 'bsc', 'chiliz'],
        distributeBlockchain: 'chiliz',
        mintBlockchain: 'chiliz',
        paymentPriority: ['chiliz', 'polygon', 'bsc'],
        activatedRarities: [
            type_1.RarityLevel.FUNGIBLE,
            type_1.RarityLevel.RARITY_3,
            type_1.RarityLevel.RARITY_2,
        ],
        enableConditionBonus: false,
        displayCardModifiers: false,
    };
