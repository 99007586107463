import MuiAlert, { alertClasses } from '@mui/material/Alert';
import React, {
  cloneElement,
  PropsWithChildren,
  ReactElement,
  SyntheticEvent,
} from 'react';
import styled, { css } from 'styled-components';

import { IconSlot, TIconProp } from '../../icons';
import { Button, IconButton } from '../../inputs';
import { Paragraph } from '../../typography';

const StyledAlert = styled(MuiAlert)<{
  $longAction?: boolean;
}>`
  ${({ theme, $longAction }) => css`
    &.${alertClasses.root} {
      align-items: center;
      box-sizing: border-box;
      background: ${theme.color.primary['80']};
      border-radius: 8px;
      padding: ${theme.spacing(24)};
      gap: ${theme.spacing(16)};
      flex-wrap: ${$longAction && 'wrap'};

      .${alertClasses.icon} {
        color: ${theme.color.primary['0']};
        margin: 0;
        padding: 0;
        opacity: 1;
      }

      .${alertClasses.message} {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 0;
        flex-basis: ${$longAction &&
        'calc(100% - 24px - 16px)'}; // 100% - icon - gap
      }

      .${alertClasses.action} {
        align-items: center;
        justify-content: ${$longAction && 'end'};
        gap: ${theme.spacing(8)};
        padding: 0;
        flex-basis: ${$longAction && '100%'};
        &:empty {
          display: none;
        }
      }
    }

    &.${alertClasses.outlined} {
      border: 1px solid ${theme.color.primary['Light 10%']};
    }
  `};
`;

const ActionButton = styled(Button)`
  display: flex;
`;

export interface IAlertProps {
  icon?: TIconProp;
  title?: string;
  action?:
    | string
    | ReactElement<{
        onClick?(event: SyntheticEvent<HTMLElement, Event>): void;
      }>;
  longAction?: boolean;
  closeable?: boolean;
  onAction?(event: SyntheticEvent<HTMLElement>): void;
  onClose?(): void;
}

/**
 * Use `Alert` to display a short, important message in a way that attracts the user's attention without interrupting the user's task.
 */
export const Alert = ({
  icon,
  title,
  children,
  action,
  longAction,
  closeable,
  onAction,
  onClose,
  ...props
}: PropsWithChildren<IAlertProps>) => (
  <StyledAlert
    {...props}
    $longAction={longAction}
    icon={icon ? <IconSlot source={icon} size="L" /> : false}
    variant="filled"
    action={
      <>
        {action &&
          (typeof action === 'string' ? (
            <ActionButton
              label={action}
              onClick={onAction}
              variant="filled"
              color="accent"
              size="S"
            />
          ) : (
            cloneElement(action, { onClick: onAction })
          ))}
        {closeable && (
          <IconButton
            icon="close__filled"
            variant="text"
            size="S"
            onClick={onClose}
          />
        )}
      </>
    }
  >
    {title && (
      <Paragraph variant="M" bold>
        {title}
      </Paragraph>
    )}
    <Paragraph
      variant="M"
      multiline
      $textColor={({ primary }) => primary['20']}
    >
      {children}
    </Paragraph>
  </StyledAlert>
);

export { alertClasses };
