import React from 'react';
import styled from 'styled-components';

import LogoPng from './logo.png';

const LogoContainer = styled.div<{ width?: number | string }>`
  position: relative;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
`;

const Image = styled.img`
  width: 100%;
`;

export interface ILogoProps {
  variant?: 'horizontal' | 'vertical';
  width?: number;
  isBeta?: boolean;
}

export function Logo({ width = 226 }: ILogoProps) {
  return (
    <LogoContainer width={width}>
      <Image src={LogoPng} />
    </LogoContainer>
  );
}
