import React, { PropsWithChildren, useMemo } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { Brand, SoccerData } from 'ultimate-league-common';

import { IconFont } from '../components';
import { BASKETBALL_THEME, FOOTBALL_THEME, LFP_THEME } from './legacy/const';
import { MUITheme } from './mui';

/* Styled Components */

const GlobalStyle = createGlobalStyle`
  html, body, #root  {
    height: 100%;
  }

  // TODO replace legacy colors as soon as sys tokens are implemented
  body {
    color: ${({ theme: { color } }) => color.primary['0']};
    background-color: ${({ theme: { color } }) => color.primary['100']};
  }
  
  // Ugly fix to remove backdrop from Select (MuiTextField) component
  .MuiMenu-root > .MuiBackdrop-root {
    background-color: transparent;
  }

  .simplebar-scrollbar::before {
      background-color: ${({ theme }) => theme.color.primary['Light 10%']};
  }
`;

const getTheme = (sport: SoccerData.Sport) =>
  // TODO merge with sys tokens as soon as they are implemented
  Brand.switchBrand({
    UC: {
      [SoccerData.Sport.SOCCER]: FOOTBALL_THEME,
      [SoccerData.Sport.BASKETBALL]: BASKETBALL_THEME,
    },
    LFP: {
      [SoccerData.Sport.SOCCER]: LFP_THEME,
      [SoccerData.Sport.BASKETBALL]: LFP_THEME,
    },
  })[sport];

/* Main Component */

export interface IDesignSystemProps {
  sport: SoccerData.Sport;
  nested?: boolean;
}

/**
 * use `Theme` as a wrapper around the app to ensure consistent spacing, colors and responsiveness across all styled components
 */
export function DesignSystem({
  sport,
  nested,
  children,
}: PropsWithChildren<IDesignSystemProps>) {
  const theme = useMemo(() => getTheme(sport), [sport]);

  return (
    <ThemeProvider theme={theme}>
      {!nested && (
        <>
          <GlobalStyle />
          <IconFont />
        </>
      )}
      <MUITheme>{children}</MUITheme>
    </ThemeProvider>
  );
}

export { useTheme } from 'styled-components';
