"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultTranslations = exports.defaultLang = void 0;
var type_1 = require("./type");
var defaultLang;
var defaultTranslations;
var defaultDirPath = './values/default';
if (process.env.BRAND === 'UC') {
    exports.defaultLang = defaultLang = type_1.LANG.EN;
    exports.defaultTranslations = defaultTranslations = __assign(__assign({}, require("".concat(defaultDirPath, "/en-web.json"))), require("./values/Euro2024/fr-web.json"));
}
if (process.env.BRAND === 'LFP') {
    exports.defaultLang = defaultLang = type_1.LANG.FR;
    exports.defaultTranslations = defaultTranslations = __assign(__assign({}, require("".concat(defaultDirPath, "/fr-web.json"))), require("./values/LFP/fr-web.json"));
}
