"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PLACEHOLDER_OBJECTIVES = exports.Progress = exports.Objectives = exports.State = void 0;
var technical_1 = require("../technical");
var State;
(function (State) {
    /**
     * The user needs to set up profile (avatar, name, favorite club)
     */
    State["WELCOME"] = "WELCOME";
    State["SELECT_CLUB"] = "SELECT_CLUB";
    /**
     * The user needs to draft initial cards
     *
     * SYS guide USR to draft initial cards
     * USR drafts initial cards
     */
    State["DRAFT"] = "DRAFT";
    /**
     * The user needs to be briefed how to meet on-boarding objectives
     *
     * SYS introduce USR to on-boarding rewards
     * USR claims reward
     */
    State["OBJECTIVES"] = "OBJECTIVES";
    /**
     * The user needs to be briefed to play Leagues Mode
     *
     * SYS introduce USR to Leagues Mode
     * USR registers a team
     */
    State["LEAGUES"] = "LEAGUES";
    /**
     * The user needs to play and collect all on-boarding rewards
     *
     * SYS let USR play the game
     * USR meets all on-boarding objectives
     */
    State["GAMING"] = "GAMING";
    /**
     * The user has met all on-boarding objectives
     */
    State["DONE"] = "DONE";
})(State || (exports.State = State = {}));
var Objectives;
(function (Objectives) {
    /**
     * Objective that requires user to complete drafting their initial squad.
     */
    Objectives["SQUAD"] = "SQUAD";
    /**
     * Objective that requires user to register their squad for league play.
     */
    Objectives["REGISTER"] = "REGISTER";
    /**
     * Objective that requires user to open their Welcome Pack in the Pack Store.
     */
    Objectives["PACK"] = "PACK";
    /**
     * Objective that requires user to complete play for a gameweek.
     */
    Objectives["GAMEWEEK"] = "GAMEWEEK";
})(Objectives || (exports.Objectives = Objectives = {}));
var Progress;
(function (Progress) {
    /**
     * Objective not yet accomplished.
     */
    Progress["INITIAL"] = "INITIAL";
    /**
     * Objective accomplished but reward has not yet been claimed.
     */
    Progress["PENDING"] = "PENDING";
    /**
     * Objective accomplished and reward has been claimed.
     */
    Progress["COMPLETED"] = "COMPLETED";
})(Progress || (exports.Progress = Progress = {}));
exports.PLACEHOLDER_OBJECTIVES = (0, technical_1.enumToArray)(Objectives).map(function (objective) { return ({
    objective: objective,
    progress: Progress.COMPLETED,
}); });
