import styled from 'styled-components';

export interface IPageContentProps {
  maxWidth?: number;
  fullWidth?: boolean;
}

export const PageContent = styled.div<IPageContentProps>`
  width: 100%;
  max-width: ${({ fullWidth, maxWidth = 1488 }) =>
    fullWidth ? undefined : `${maxWidth}px`};
  margin-inline: auto;
  box-sizing: border-box;

  padding-inline: ${({ theme }) => theme.spacing(16)};

  transition: padding 300ms ease-in-out;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-inline: ${({ theme }) => theme.spacing(24)};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-inline: ${({ theme }) => theme.spacing(48)};
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding-inline: ${({ theme }) => theme.spacing(64)};
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    padding-inline: ${({ fullWidth }) => (fullWidth ? undefined : 0)};
  }
`;

export const FullWidthSection = styled.div`
  margin-inline: -${({ theme }) => theme.spacing(16)};

  transition: margin 300ms ease-in-out;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-inline: -${({ theme }) => theme.spacing(24)};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-inline: -${({ theme }) => theme.spacing(48)};
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin-inline: -${({ theme }) => theme.spacing(64)};
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    margin-inline: 0;
  }

  > * {
    padding-inline: ${({ theme }) => theme.spacing(16)};

    transition: padding 300ms ease-in-out;

    ${({ theme }) => theme.breakpoints.up('sm')} {
      padding-inline: ${({ theme }) => theme.spacing(24)};
    }

    ${({ theme }) => theme.breakpoints.up('md')} {
      padding-inline: ${({ theme }) => theme.spacing(48)};
    }

    ${({ theme }) => theme.breakpoints.up('lg')} {
      padding-inline: ${({ theme }) => theme.spacing(64)};
    }

    ${({ theme }) => theme.breakpoints.up('xl')} {
      padding-inline: 0;
    }
  }
`;
