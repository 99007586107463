import styled, { css } from 'styled-components';
import { Brand } from 'ultimate-league-common';

import { catchMissingSwitchCase } from '#technical/catchMissingSwitchCase';

import { ITypographyProps, typographyStyles } from '../common';

type KTypeScale = 'XXL' | 'XL' | 'L' | 'M' | 'S' | 'XS';

const getFontSize = (typeScale: KTypeScale): number => {
  switch (typeScale) {
    case 'XXL':
      return 22;
    case 'XL':
      return 18;
    case 'L':
      return 16;
    case 'M':
      return 14;
    case 'S':
      return 12;
    case 'XS':
      return 11;
    default:
      throw catchMissingSwitchCase(typeScale);
  }
};

export interface IParagraphProps extends ITypographyProps {
  /**
   * Figma text style that should be used globally or per supported viewport
   *
   * @example global
   * <Paragraph variant="M">I am M on all viewports</Paragraph/>
   *
   * @example per-viewport
   * <Paragraph
   *  variant={{
   *    M: 'S',
   *    T: 'M',
   *    D: 'L'
   *  }}>
   *    I am S on [M]obile, M on [T]ablet and L on [D]esktop
   * </Paragraph/>
   */
  variant: KTypeScale | Record<'M' | 'T' | 'D', KTypeScale>;
  bold?: boolean;
  multiline?: boolean;
  uppercase?: boolean;
}

export const Paragraph = styled.p<IParagraphProps>`
  font-family: ${Brand.switchBrand({
    UC: `'Manrope', sans-serif`,
    LFP: `Bai Jamjuree, sans-serif`,
  })};
  line-height: ${({ multiline }) => (multiline ? '140%' : '120%')};
  font-weight: ${({ bold }) => (bold ? 'bold' : undefined)};
  // Note: lines on zeros are disabled because they don't render well with a font weight of 600
  font-feature-settings: ${Brand.switchBrand({
    UC: `'pnum' on, 'lnum' on, 'ss03' on, 'zero' off`,
    LFP: 'inherit',
  })};
  text-transform: ${({ uppercase }) => uppercase && 'uppercase'};

  // Font size
  ${({ variant, theme: { breakpoints } }) => {
    if (typeof variant === 'string')
      return css`
        font-size: ${getFontSize(variant)}px;
      `;
    // Responsive font size
    return css`
      font-size: ${getFontSize(variant.M)}px;
      ${breakpoints.up('md')} {
        font-size: ${getFontSize(variant.T)}px;
      }
      ${breakpoints.up('lg')} {
        font-size: ${getFontSize(variant.D)}px;
      }
    `;
  }}

  // Common styles
  ${(props) => typographyStyles(props)}
`;
